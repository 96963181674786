import React from 'react';

import Layout from '../components/Layout';
import pic1 from '../assets/images/rien-avatar2.png';

import SEO from "../components/Seo"
import { Link } from 'gatsby';

const ResumePage = () => (
  <Layout>
	<SEO title ="Resume" description="Darien Aris Kirst — Freelance Writer & Illustrator" />
	<div id="main">
		<div className="inner">
		<span className="image left"><img src={pic1} alt="" /></span>
		<div className="resumeTitle">
		<h1>Darien Aris Kirst</h1><h2>Freelance Writer & Illustrator</h2>
		</div> 
		<div className="resumeAbout">
		<p>Possessing a voice to speak and tell thoughts often locked away— writing has always been a source of comfort for me. And while it’s useful to have a means of communicative clarity, I’ve always been drawn to fiction. To take a moment to escape real life and get lost in somewhere far beyond has always brought me joy. What brings me even more happiness, however, is to share those worlds with others and to give everyone the opportunity to read the words often trapped within one’s own mind. </p>
		</div>
		<br/><br/>
		<br/><br/>
		<h3>Experience</h3>
		<Link to="https://tromb.com/">MNMT Crew (acquired by Tromb) — Creative Associate</Link>
		<ul>
			<li>Social media marketing</li>
			<li>Graphic design</li>
			<li>Copywriting and proofreading</li>
		</ul>

		<Link to="https://wanderlustsaga.com/">Wanderlust Studio — Writer</Link>
		<ul>
			<li>Storytelling and creative writing</li>
			<li>Character design</li>
		</ul>

		<Link to="https://www.animegeek.com/">Anime Geek — Writer</Link>
		<ul>
			<li>Media research and fact-checking</li>
			<li>Interviews, reviews, and curation</li>
		</ul>

		<Link to="https://www.linkedin.com/in/darien-kirst-225274230/">Freelance</Link>
		<ul>
			<li>Web content writing</li>
			<li>Journalism</li>
			<li>Copy editing</li>
		</ul>
		<h3>Education</h3>
		<Link to="https://ccts.org/">Camden County Technical School — Video Production</Link>
		<ul>
			<li>Scriptwriting</li>
			<li>Video editing</li>
			<li>Set production and planning</li>
		</ul>

		<Link to="https://www.lulea.se/utbildning--forskola/vuxenutbildning.html">Luleå Vuxenutbildningen — Swedish</Link>
		<ul>
			<li>Swedish grammar and vocabulary</li>
		</ul>

		<Link to="https://www.sunderbyfolkhogskola.se/">Sunderby Folkhögskola — General Art</Link>
		<ul>
			<li>Watercolor painting</li>
			<li>Sculpting</li>
		</ul>
		<h3>Certifications</h3>
		<ul>
			<li><Link to="https://www.credly.com/badges/b127af48-5101-4f9b-a56f-a1bbba3c2621">Unity Essentials Pathway</Link></li>
			<li><Link to="https://www.credly.com/badges/81e5d607-7691-4cf1-a806-b68bb3eec983/public_url">Unity Junior Programmer</Link></li>
			<li><Link to="https://www.credly.com/badges/ccdf2891-177a-4c34-86c1-68eb90dd1ce6">Big Data Foundations - Level 1</Link></li>
		</ul>
		</div>
	</div>

	

  </Layout>
);

export default ResumePage;
